export const favorite = [

    {
        id: '1',
        image: require('../../asserts/images/favoriteImages.png'),
        name: "Ahmed Khan",
        Location: "Bruxelles, Belgium",
        Riwaya: 'Hafs, Warsh',
        Studygoal: " Revision, Memorization",
        Memorizationlevel: " 15 juzz",
        Language: "French, English "
    },
    {
        id: '2',
        image: require('../../asserts/images/favoriteImages.png'),
        name: "Ahmed Khan",
        Location: "Bruxelles, Belgium",
        Riwaya: 'Hafs, Warsh',
        Studygoal: " Revision, Memorization",
        Memorizationlevel: " 15 juzz",
        Language: "French, English "

    },
    {
        id: '3',
        image: require('../../asserts/images/favoriteImages.png'),
        name: "Ahmed Khan",
        Location: "Bruxelles, Belgium",
        Riwaya: 'Hafs, Warsh',
        Studygoal: " Revision, Memorization",
        Memorizationlevel: " 15 juzz",
        Language: "French, English "
    },
    {
        id: '4',
        image: require('../../asserts/images/favoriteImages.png'),
        name: "Ahmed Khan",
        Location: "Bruxelles, Belgium",
        Riwaya: 'Hafs, Warsh',
        Studygoal: " Revision, Memorization",
        Memorizationlevel: " 15 juzz",
        Language: "French, English "
    },

];