export const favoriteEvents = [

    {
        id: '1',
        image: require('../../asserts/images/favEvents.png'),
        name: "Islamic Art & Calligraphy ExhibitionKhan",
        desc: "Islamic Cultural Center • Bruxelles, Belgium & Online",
        Attendees: '20 Attendees',
        date: 'WED • APR 26 • 7 PM CEST '
    },
    {
        id: '2',
        image: require('../../asserts/images/favEvents.png'),
        name: "Fatima Al-Rashid",
        desc: "Islamic Cultural Center • Bruxelles, Belgium & Online",
        Attendees: '20 Attendees',
        date: 'WED • APR 26 • 7 PM CEST '


    },
    {
        id: '3',
        image: require('../../asserts/images/favEvents.png'),
        name: "Fatima Al-Rashid",
        desc: "Islamic Cultural Center • Bruxelles, Belgium & Online",
        Attendees: '20 Attendees',
        date: 'WED • APR 26 • 7 PM CEST '

    },
    {
        id: '4',
        image: require('../../asserts/images/favEvents.png'),
        name: "Fatima Al-Rashid",
        desc: "Islamic Cultural Center • Bruxelles, Belgium & Online",
        Attendees: '20 Attendees',
        date: 'WED • APR 26 • 7 PM CEST '

    },
];