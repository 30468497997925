import React from 'react'
import Messages from '../../../StudentDashboard/SidebarContent/Chat/Messages'
function OrgMessage() {
  return (
    <div>
      <Messages  role={"organization"}/>
    </div>
  )
}

export default OrgMessage