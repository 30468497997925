import React, { useEffect, useState } from "react";
import "./OrgDashboard.css";
import { UpComingEvents } from "./UpComingEvents";
import { ResourceLibrary } from "./ResourceLibrary";
import { MessageSection } from "./MessageSection";
import { FeedbackMain } from "./FeedbackMain";
import { Analytics } from "./Analytics";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../../Store/Index";
import { useDispatch } from "react-redux";
import { getCookies } from "../../../lib/auth";
import { useNavigate } from "react-router-dom";
const OrgDashboardMain = () => {
const token = getCookies("token")
const navigate = useNavigate()
  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  }, [ ])
  
  const dispatch = useDispatch();
  const { orgDashboardIndex } = bindActionCreators(actionCreaters, dispatch);
  const [containerHeight, setContainerHeight] = useState(400);
  const [biggerDivHeight, setBiggerDivHeight] = useState(0);
  const [smallerDivHeight, setSmallerDivHeight] = useState(0);
  const [extrasmallerDivHeight, setSExtramallerDivHeight] = useState(0);
  useEffect(() => {

    const updateContainerHeight = () => {
      const clientHeight = window.innerHeight;

      const ratioBiggerDiv = 0.4;
      const ratioSmallerDiv = 0.30;
      let calculatedSmallerDivHeight
      let calculatedBiggerDivHeight

      setContainerHeight(window.innerHeight - 62);


      if (clientHeight < 900) {

        calculatedSmallerDivHeight = clientHeight * ratioSmallerDiv - 30;
        calculatedBiggerDivHeight = clientHeight * ratioBiggerDiv - 30;
        setSExtramallerDivHeight(calculatedBiggerDivHeight / 2 - 4);
      }
      else {
        calculatedSmallerDivHeight = clientHeight * ratioSmallerDiv;
        calculatedBiggerDivHeight = clientHeight * ratioBiggerDiv - 16;
        setSExtramallerDivHeight(calculatedBiggerDivHeight / 2 - 4);

      }


      if (calculatedBiggerDivHeight <= 240) {
        setBiggerDivHeight(240);
      } else {
        setBiggerDivHeight(calculatedBiggerDivHeight);

      }

      if (calculatedSmallerDivHeight <= 200) {
        setSmallerDivHeight(200);
      } else {

        setSmallerDivHeight(calculatedSmallerDivHeight);
      }


    };

    // Initial update
    updateContainerHeight();

    // Event listener for window resize
    window.addEventListener('resize', updateContainerHeight);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateContainerHeight);
    };


  }, []);

  return (

    <div className="mainDashboardPage">
      <div style={{ backgroundColor: "#F3FAFC", width: "100%", zIndex: -1, height: window.innerHeight }} className="position-absolute top-0 left-0 right-0 bottom-0 h-100"></div>
      <div style={{ maxWidth: "1800px", margin: "auto" }} >
        <div className="orgDashboardMain pt-4 z-1">
          <div className="row g-3 orgDashboardMainRow" >
            <div className="col-xl-7 col-lg-6  mt-0">
              <UpComingEvents biggerDivHeight={biggerDivHeight} orgDashboardIndex={orgDashboardIndex} />
            </div>
            <div className="col-xl-5 col-lg-6 mt-4 mt-lg-0">
              <FeedbackMain biggerDivHeight={biggerDivHeight} orgDashboardIndex={orgDashboardIndex} />
              {/* <Analytics orgDashboardIndex={orgDashboardIndex} /> */}
            </div>
            <div className=" col-lg-6 mt-4 mt-lg-2">
              <ResourceLibrary smallerDivHeight={smallerDivHeight} orgDashboardIndex={orgDashboardIndex} />
            </div>
            <div className="col-lg-6 mt-4 mt-lg-2">
              <MessageSection smallerDivHeight={smallerDivHeight} orgDashboardIndex={orgDashboardIndex} />
            </div>
            {/* <div className="col-xxl-4 col-lg-6 ">

          </div> */}
          </div>
        </div>
      </div>
    </div>

  );
};

export default OrgDashboardMain;



