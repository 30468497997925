import React from 'react'
import Button from './Button'
import img from '../../asserts/images/people.png'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import secureLocalStorage from "react-secure-storage";
import { getCookies } from '../../lib/auth'
const PeopleSection = ({ title }) => {
    const { t, i18n } = useTranslation();
    const role = secureLocalStorage.getItem("role");
    const token = getCookies("token")
    document.body.dir = i18n.dir();
    const navigate = useNavigate();
    return (
        <>
            <section >
                <div className="container">
                    <div className="row people-main">
                        <div className='bg-pink'></div>
                        <div className='people-box'>
                            <h6>{title}</h6>
                            {/* {!token ? <Button data={'Sign up'} class={'profile-btn people-btn'} onClick={() => navigate('/signup')}>  </Button> : <Button data={'Go to Dashboard'} class={'profile-btn people-btn'} onClick={() => navigate(`${role === 'student' ? '/student-dashboard/0' : '/organization-dashboard/0'}`)}>  </Button>} */}


                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PeopleSection
