export const vacancy = [

    {
        id: 1,
        title: 'Exhibition Staff',
        PostedTime: "20:00 PM • May 27 2023",
        Location: 'Bruxelles',
        Status: "Open to application",
        rating: 5,
        Candidate: '29',
    },

    {
        id: 2,
        PostedTime: "20:00 PM • May 27 2023",
        title: 'Content Contributor',
        Location: 'Bruxelles',
        Status: "Closed",
        rating: 5,
        Candidate: '29',
    },


]