import React, { useEffect, useState, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { ProductService } from './service/ProductService';
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { BsChevronDown } from "react-icons/bs";
import { LuSend } from "react-icons/lu";
import { BiChevronDown, BiChevronRight, BiPlusCircle } from "react-icons/bi";
import { MdClose, MdEdit } from "react-icons/md";
import img1 from "../../../asserts/images/Avatar (1).png";
import img2 from "../../../asserts/images/mamber2.png";
import Download from "../../../asserts/images/download.svg";
import { ReactSVG } from "react-svg";
import myEventCloseIcon from "../../../asserts/images/myEventCloseIcon.png";
import downArrow from "../../../asserts/images/downArrow.png";
import edit from "../../../asserts/images/orgEditIcon.png";
import addBtn from "../../../asserts/images/newMsg.png";
import { Calendar } from "primereact/calendar";
import { Loader } from "../../../components/reuseable/Loader";
import secureLocalStorage from "react-secure-storage";
// import { BiChevronRight } from "react-icons/bi"; // Import the icons you need
import Buttons from "../../../components/reuseable/Button";
import set from "date-fns/set";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { showMessage } from "../../../components/reuseable/Tostify";
import API_Routes from "../../../Routes/API_Routes";
import { DataArrayRounded } from "@mui/icons-material";
import { getCookies } from "../../../lib/auth";
// import ProductsDemo from './GoalsTasks'
const Modal = () => {
  // let item = null
  const [productDialog, setProductDialog] = useState(true);
  // const [products, setProducts] = useState(null);

  const [hideModal, setHideModal] = useState(true);
  const [product, setProduct] = useState({});
  const [allSelectedItem, setAllSelectedItem] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [markTxt, setMarkTxt] = useState("");
  const [markIds, setMarkIds] = useState([]);
  const [selectedArrayTasks, setSelectedArrayTasks] = useState([]);

  const [products, setProducts] = useState(null);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showOption, setShowOption] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalCategory, setModalCategory] = useState(false);
  const [modalPriority, setModalPriority] = useState(false);
  const [modalMember, setModalMember] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [rowClick, setRowClick] = useState(true);
  const [deletedPro, setDeletedPro] = useState();
  const [member, setMember] = useState();
  const [selectedMembers, setSelectedMembers] = useState([]); // Initialize selected members as an empty array
  const [isEdit, setIsEdit] = useState(false);
  const [selectedActionText, setSelectedActionText] = useState("");

  const [show, setShow] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const [done, setDone] = useState([]);
  const [inProgress, setInprogress] = useState([]);
  const [notStarted, setNotStarted] = useState([]);
  const [value, setValue] = useState(false);
  const [taskStatus, setTaskStatus] = useState("");
  const [taskDueDate, setTaskDueDate] = useState("");
  const [priority, setPriority] = useState("");
  const [TaskMember, setTaskMember] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [taskComment, setTaskComment] = useState(false);
  const [search, setSearch] = useState("");
  const [deleteProductID, setDeleteProductID] = useState("");
  const [taskData, setTaskData] = useState([]);

  const childRef = useRef();
  const selectedItem = useSelector((state) => state?.studentIndex);
//  const data = useSelector((state) => state.Data);
//  console.log("newlifeeeeeeeeeeeeeeeeeeeeeeeeeeee", data.data);

  const [isInputActive, setInputActive] = useState(false);
  const [newCategory, setNewCategory] = useState("");

  const dropdownRef = useRef(null);

  const [filterTask, setFiltertask] = useState(null);

  const location = useLocation();
  // console.log('locationData', location?.state?.item)
  const paramDate = location?.state?.item;
  const handleFilter = (item, index) => {
    setIsOpen2(!isOpen2);
    setFiltertask(index);
  };

  const handleDelete = (item) => {
    // console.log('itemasasas', item)
    if (!allSelectedItem?.length) {
      showMessage("Please select Items to delete", "warning");
    } else {
      setOpenDelete(true);
    }
  };
  const ref = useRef(null);
  useEffect(() => {
    // console.log(token)
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    GetTaskList();
  }, []);
  function updateSelectedIds(selectedIds, newId) {
    if (selectedIds?.length) {
      if (newId !== undefined && !selectedIds.includes(newId)) {
        selectedIds.push(newId);
      }
      return selectedIds;
    } else {
      return;
    }
  }
  const sendUpdatedTaskData = (selectedIds, txt) => {
    let selectedIdsdata = updateSelectedIds(selectedIds, product._id);
    const inputDate = taskDueDate;
    const formattedDate = changeDateFormat(product?.dueDate);
    const membersData = product?.member?.map((item) => {
      return item?.member?.id;
    });
    // console.log('update:::', product)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    // myHeaders.append("Content-Type", "application/json");
    var formdata = new FormData();
    if (selectedIdsdata?.length) {
      selectedIdsdata?.forEach((i) => formdata.append("id[]", i));
    } else {
      formdata.append("id[]", product?._id);
    }

    // product?.status && formdata.append("status", product?.status);
    txt
      ? formdata.append("status", txt)
      : product?.status && formdata.append("status", product?.status);

    product?.category && formdata.append("category", product?.category);
    formattedDate && formdata.append("dueDate", formattedDate);
    product?.priority && formdata.append("priority", product?.priority);
    // product?.member.length &&
    // formdata.append("members", membersData);
    membersData?.forEach((e) => {
      formdata.append("members", e);
    });
    product?.description &&
      formdata.append("description", product?.description);
    product?.comment && formdata.append("comment", product?.comment);
    product?.name && formdata.append("name", product?.name);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(API_Routes.UPDATEDGOALSTASK, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 200 || result.status == 201) {
          // console.log('Updated successfully--->', result)
          setHideModal(true);
          GetTaskList();
          // childRef.current
          // setHideModal(true)
          setLoader(false);
          showMessage(result?.message);
          setSelectedArrayTasks([]);
          selectedIdsdata = [];
          selectedIds = [];
          setProduct({});
        } else {
          setLoader(false);
          showMessage(result?.message, "error");
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  };
  const GetTaskList = () => {
    setLoader(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(API_Routes.GETALLGOALTASK, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 200 || result.status == 201) {
          setTaskData(result);
          setOpenDelete(false);

          const data = result?.data?.filter((item) => {
            if (item.status == "Done") {
              return item;
            }
          });

          setDone(data);

          const data2 = result?.data?.filter((item) => {
            return item.status == "In progress";
          });

          setInprogress(data2);

          const data3 = result?.data?.filter((item) => {
            if (item.status == "Not started") {
              return item;
            }
          });

          setNotStarted(data3);
          // setValue(true);
          setLoader(false);
        } else {
          showMessage(result?.message, "error");
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  };
  function changeDateFormat(inputDate) {
    return inputDate?.split("-").join("/");
  }
  const data = useSelector((state) => state.Data.data);
  // const special =()=>{
    
  //   // openNew();
  //   if (data !== null && data !== undefined) {
  //     console.log("newssssssssss", data);
  //     openNew();
  
  //     const date = new Date(selectedItem);
  //     const year = date.getFullYear();
  //     const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are zero-based
  //     const day = date.getDate().toString().padStart(2, "0");
  
  //     const formattedDate = `${year}-${month}-${day}`;
  //     setProduct((prevProduct) => ({ ...prevProduct, dueDate: formattedDate }));
  //   }
  // }
  // special()
  // console.log("newssssssssss", data);
  // useEffect(() => {
  //   special()
  //   // const data = useSelector((state) => state.Data);
  //   // // openNew();
  //   // if (data !== null && data !== undefined) {
  //   //   console.log("newssssssssss", data);
  //   //   openNew();
  
  //   //   const date = new Date(selectedItem);
  //   //   const year = date.getFullYear();
  //   //   const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are zero-based
  //   //   const day = date.getDate().toString().padStart(2, "0");
  
  //   //   const formattedDate = `${year}-${month}-${day}`;
  //   //   setProduct((prevProduct) => ({ ...prevProduct, dueDate: formattedDate }));
  //   // }
  // });

  useEffect(() => {
    if (data !== null && data !== undefined) {
      console.log("newssssssssss", data);
      openNew();
  
      const date = new Date(data);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are zero-based
      const day = date.getDate().toString().padStart(2, "0");
  
      const formattedDate = `${year}-${month}-${day}`;
      setProduct((prevProduct) => ({ ...prevProduct, dueDate: formattedDate }));
    }
  }, [data, selectedItem]);
  
  // Add this condition at the beginning of your component's render method
  // if (data === null || data === undefined) {
  //   return null;
  // }


  const openNew = () => {
    setProduct({});
    // setSubmitted(false);
    setProductDialog(true);
  };
  const updateSelectedActionText = (selectedItems) => {
    if (selectedItems.length === 0) {
      setTaskComment(false);
      setSelectedActionText("");
    } else {
      setSelectedActionText(`(${selectedItems.length} selected)`);
    }
  };

  const handleMarkAction = (txt) => {
    const selectedIds = Array.from(
      new Set(selectedProducts?.map((item) => item._id))
    );
    // console.log('selectedIds==>', selectedIds, txt)
    // setMarkIds(selectedIds);
    // setMarkTxt(txt);
    // setSelectedArrayTasks(selectedProducts)
    sendUpdatedTaskData(selectedIds, txt);
    setTaskComment(false);
  };

  const handleMarkAsNotStarted = () => {};

  const handleDeleteSelectedItems = () => {};

  const toggleDropdown = () => {
    setModalCategory(!modalCategory);
  };

  const handleInputClick = () => {
    setModalCategory(!modalCategory);
    if (!modalCategory) {
      setNewCategory("");
    }
  };

  const handleInputChange = (e) => {
    setNewCategory(e.target.value);
  };

  const handleAddCategory = () => {
    if (newCategory.trim() !== "") {
      setProduct({ ...product, category: newCategory });
      setNewCategory("");
      setModalCategory(false);
    }
  };

  // console.log('product0900-->', product)

  const handleCategoryClick = (category) => {
    setProduct({ ...product, category });
    setModalCategory(false); // Close the dropdown when an option is clicked
  };
  const token = getCookies("token");

  const toast = useRef(null);
  const dt = useRef(null);
  const dummyData = [
    { img: img1, name: "Jade Doe" },
    { img: img1, name: "Jade Doe" },
    { img: img1, name: "Jade Doe" },
    { img: img1, name: "Jade Doe" },
  ];

  useEffect(() => {
    GetMembersData();
    if (hideModal) {
      setSubmitted(false);
      setProductDialog(false);
      setHideModal(false);
      setSelectedProducts([]);
      setShowOption(false);
      setTaskComment(false);
    }
  }, [hideModal]);

  useEffect(() => {
    if (openDelete) {
      setDeleteProductsDialog(true);
    }
  }, [openDelete]);

  const GetMembersData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://apis.ilmcircle.com/api/student/task/member/get",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log('JSONData -> ', result)
        setMember(result?.data);
      })
      .catch((error) => console.log("error", error));
  };

  // useEffect(() => {
  //   setProducts(item);
  // }, [item]);

  const formatCurrency = (value) => {
    return value;
  };

  const ClickedRow = (e) => {
    if (e.field !== "selection") {
      setRowClick(true);
      setProduct(e.data);
      setSelectedRowData(e.data);
      setProductDialog(true);
      setTaskComment(false);
    }
  };

  //   const openNew = () => {
  //     setProduct({});
  //     setSubmitted(false);
  //     setProductDialog(true);
  //   };
  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };
  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };
  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
    setOpenDelete(false);
  };

  // console.log('product.....=>', product)

  const editProduct = (product) => {
    setProduct({ ...product });
    setProductDialog(true);
  };
  const modalHeader = (pro) => {
    // console.log('pro--=-=-=-=->', pro)
    return (
      <div className="header d-flex justify-content-between">
        <input
          style={{ border: "none" }}
          id="Name"
          disabled={!isEdit}
          placeholder="Untitled task"
          className="unitedTaskstyle w-100  p-1 mt-2"
          value={product?.name}
          // onValueChange={(e) => onInputNumberChange(e)}
          onChange={(e) => {
            setProduct({ ...product, name: e.target.value });
          }}
        />

        {/* <span className="me-2">Untitled task</span> */}
        <div className="p-dialog-header-icons-custom d-flex align-items-center gap-4">
          <img
            className=" "
            src={edit}
            onClick={() => setIsEdit(!isEdit)}
            alt="edit"
            style={{
              width: 20.83,
              height: 20.83,
              // marginBottom: 31,
              cursor: "pointer",
            }}
          />

          <img
            className=""
            onClick={hideDialog}
            src={myEventCloseIcon}
            alt="myEventCloseIcon"
            style={{
              width: 16.15,
              height: 16.15,
              // marginBottom: 31,
              cursor: "pointer",
            }}
          />
          <ReactSVG
            style={{ cursor: "pointer" }}
            className="mb-3 ms-1 iconModal"
            src={Download}
          />
        </div>
      </div>
    );
  };

  const toggleMemberSelection = (item) => {
    const isSelected = selectedMembers?.some(
      (selected) => selected?.member?.id === item?.member?.id
    );

    if (isSelected) {
      setSelectedMembers((prevSelectedMembers) =>
        prevSelectedMembers.filter(
          (selected) => selected.member.id !== item.member.id
        )
      );
    } else {
      setSelectedMembers((prevSelectedMembers) => [
        ...prevSelectedMembers,
        item,
      ]);
    }

    setProduct((prevProduct) => {
      const updatedMembers = isSelected
        ? (prevProduct.member || []).filter(
            (selected) => selected.member.id !== item.member.id
          )
        : [...(prevProduct.member || []), item];

      return { ...prevProduct, member: updatedMembers };
    });
  };
  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _product = { ...product };

    _product[`${name}`] = val;

    setProduct(_product);
  };
  const sendCreateTaskData = () => {
    // console.log('product?.Name', product?.Name)
    setLoader(true);

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + token);

    const formattedDate = changeDateFormat(product?.dueDate);
    const membersData = product?.member?.map((item) => {
      return item?.member?.id;
    });
    // console.log('product?.memberCreate', membersData);

    var formdata = new FormData();
    if (product) {
      product?.status && formdata.append("status", product?.status);
      product?.category && formdata.append("category", product?.category);
      product?.dueDate && formdata.append("dueDate", formattedDate);
      product?.priority && formdata.append("priority", product?.priority);
      // product?.member.length &&
      membersData?.forEach((e) => {
        formdata.append("members", e);
      });
      product?.description &&
        formdata.append("description", product?.description);
      product?.comment && formdata.append("comment", product?.comment);
    }
    product?.name && formdata.append("name", product?.name);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://apis.ilmcircle.com/api/student/task/create",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 200 || result.status == 201) {
          setHideModal(true);
          GetTaskList();

          setLoader(false);
          showMessage(result?.message);
          setProduct({});
        } else {
          setLoader(false);
          showMessage(result?.message, "error");
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
      });
  };

  return (
    <div>
      <Dialog
        visible={productDialog}
        // style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={modalHeader}
        dismissableMask={true}
        draggable={false}
        modal
        className="p-fluid addNewTaskModal width-class "
        // footer={productDialogFooter}
        onHide={hideDialog}
      >
        <div className="field mt-3 row goalTaskDoneSection jusitfy-content-start">
          <label htmlFor="name" className="font-bold pt-2 pb-3 ps-1 pe-4 col-2">
            Status
          </label>
          <div className=" col-sm-6 col-9 ms-sm-0 ms-1">
            <div
              className="statusField py-2 ps-1 pe-1 point"
              onClick={() => setModalStatus(!modalStatus)}
            >
              {" "}
              <span className="">
                {product?.status ? product?.status : "Select"}
              </span>
              <span className="text-end ms-5">
                {modalStatus ? (
                  <BiChevronDown fontSize={"20px"} />
                ) : (
                  <BiChevronRight fontSize={"20px"} />
                )}
              </span>
            </div>
            {modalStatus ? (
              <div className="mt-2" ref={dropdownRef}>
                <div className="goalTaskDoneSection my-2">
                  <div className="innerTableColBtnHead ">
                    <button
                      className={`innerTableCol py-2 fs-16 px-3 py-2 w-75 justify-content-start`}
                      style={{ backgroundColor: "#C5E4D7" }}
                      onClick={() => {
                        setProduct({ ...product, status: "Done" });
                        // setTaskStatus("done")
                        setModalStatus(false);
                      }}
                    >
                      <div className="span"></div>
                      <div className="statusoptions">Done</div>
                    </button>
                  </div>
                </div>
                <div className="goalTaskDoneSection  my-2">
                  <div className="innerTableColBtnHead ">
                    <button
                      onClick={() => {
                        setProduct({ ...product, status: "In progress" });
                        // setTaskStatus("In progress")

                        setModalStatus(false);
                      }}
                      className={`innerTableCol Inprogress fs-16 px-3 py-2 w-75 justify-content-start`}
                    >
                      <div className="span"></div>
                      <div className="statusoptions">In progress</div>
                    </button>
                  </div>
                </div>
                <div className="goalTaskDoneSection  my-2">
                  <div className="innerTableColBtnHead ">
                    <button
                      onClick={() => {
                        setProduct({ ...product, status: "Not started" });
                        // setTaskStatus("not started")

                        setModalStatus(false);
                      }}
                      className={`innerTableCol isstarted fs-16 px-3 py-2 w-75 justify-content-start`}
                    >
                      <div className="span"></div>

                      <div className="statusoptions">Not started</div>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="field mt-3 goalTaskDoneSection row justify-content-start">
          <label htmlFor="name" className="font-bold py-2 ps-1 pe-4 col-2">
            Category
          </label>
          <div className="col-sm-6 col-9 ms-sm-0 ms-1">
            <div
              className="statusField py-2 ps-1 pe-1 point"
              onClick={toggleDropdown}
            >
              <span className="me-0">
                {product.category ? product.category : "Select"}
              </span>
              <span className="text-end">
                {modalCategory ? (
                  <BiChevronDown fontSize={"20px"} />
                ) : (
                  <BiChevronRight fontSize={"20px"} />
                )}
              </span>
            </div>
            {modalCategory ? (
              <div
                className="categoryDropDown categoryVal mt-2 "
                ref={dropdownRef}
              >
                <div
                  className="categoryGoal my-3 pointer dropDown-hover"
                  onClick={() => handleCategoryClick("Memorization")}
                >
                  Memorization
                </div>
                <div
                  className="categoryGoal my-3 pointer dropDown-hover"
                  onClick={() => handleCategoryClick("Revision")}
                >
                  Revision
                </div>
                <div
                  className="categoryGoal my-3 pointer"
                  onClick={handleInputClick}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      {modalCategory ? (
                        <input
                          type="text"
                          placeholder="Input name "
                          value={newCategory}
                          onChange={handleInputChange}
                          className="w-75"
                          onClick={(e) => e.stopPropagation()}
                        />
                      ) : (
                        "Input name"
                      )}
                    </div>
                    <img
                      className="me-2"
                      onClick={handleAddCategory}
                      style={{ width: 20, height: 20, cursor: "pointer" }}
                      src={addBtn}
                      alt="addBtn"
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="field mt-3 goalTaskDoneSection row jusitfy-content-start">
          <label htmlFor="name" className="font-bold py-2 col-2 ps-1 pe-0">
            Due date
          </label>
          <div className="col-sm-6 col-9 ms-sm-0 ms-1">
            <input
              type="date"
              disabled={selectedItem}
              placeholder="Select"
              value={product?.dueDate?.replace(/\//g, "-")}
              onChange={(e) => {
                setProduct({ ...product, dueDate: e.target.value });
                // setTaskDueDate(e.target.value)
              }}
              className="w-100 statusField py-2 ps-1 pe-1 point"
            />
          </div>
        </div>
        <div className="field mt-3 goalTaskDoneSection row jusitfy-content-start">
          <label htmlFor="name" className="font-bold py-2 ps-1 pe-4 col-2">
            Priority
          </label>
          <div className="col-sm-6 col-9 ms-sm-0 ms-1">
            <div
              className="statusField py-2 ps-1 pe-1 point"
              onClick={() => setModalPriority(!modalPriority)}
            >
              <span className="">
                {product?.priority ? product?.priority : "Select"}
              </span>
              <span className="text-end">
                {modalPriority ? (
                  <BiChevronDown fontSize={"20px"} />
                ) : (
                  <BiChevronRight fontSize={"20px"} />
                )}
              </span>
            </div>
            {modalPriority ? (
              <div className="categroyDropDown mt-2" ref={dropdownRef}>
                <div
                  className="categoryGoal my-2 point"
                  onClick={() => {
                    setProduct({ ...product, priority: "Low" });
                    // setPriority("Low")
                    setModalPriority(false);
                  }}
                >
                  Low
                </div>
                <div
                  className="categoryGoal my-2 point"
                  onClick={() => {
                    setProduct({ ...product, priority: " Medium" });
                    // setPriority("Medium")

                    setModalPriority(false);
                  }}
                >
                  Medium
                </div>
                <div
                  className="categoryGoal my-2 point"
                  onClick={() => {
                    setProduct({ ...product, priority: "High" });
                    // setPriority("High")
                    setModalPriority(false);
                  }}
                >
                  High
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="field mt-3 goalTaskDoneSection row justify-content-start">
          <label htmlFor="name" className="font-bold py-2 ps-1 pe-4 col-2">
            Member
          </label>
          <div className="col-sm-6 col-9 ms-sm-0 ms-1">
            <div
              className="statusField py-2 ps-1 pe-1 point"
              onClick={() => setModalMember(!modalMember)}
              ref={dropdownRef}
            >
              <div
                className="selected-members-container"
                style={{
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  width: "300px",
                }}
              >
                <span className="ms-1">
                  {!product?._id && selectedMembers?.length === 0
                    ? "Select "
                    : ""}{" "}
                </span>

                {selectedMembers?.length > 0
                  ? selectedMembers?.map((selected) => (
                      <div
                        key={selected.id}
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          marginRight: "10px",
                        }}
                      >
                        {selected?.member?.images &&
                          selected?.member?.images?.length > 0 && (
                            <img
                              style={{
                                width: 30,
                                height: 30,
                                borderRadius: 30,
                              }}
                              src={selected?.member?.images[0]?.url}
                              alt="image"
                            />
                          )}
                        <span className="ms-1">
                          {selected?.member?.firstName}{" "}
                          {selected?.member?.lastName}
                        </span>
                      </div>
                    ))
                  : product?.contactId?.map((selected) => (
                      <div
                        key={selected._id}
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          marginRight: "10px",
                        }}
                      >
                        {selected?.userDetailId?.image && (
                          <img
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: 30,
                            }}
                            src={selected?.userDetailId?.image?.url}
                            alt="image"
                          />
                        )}
                        <span className="ms-1">
                          {selected?.userDetailId?.firstName}{" "}
                          {selected?.userDetailId?.lastName}
                        </span>
                      </div>
                    ))}
              </div>
              <span className="text-end ms-2">
                {modalMember ? (
                  <BiChevronDown fontSize={"20px"} />
                ) : (
                  <BiChevronRight fontSize={"20px"} />
                )}
              </span>
            </div>

            {modalMember ? (
              <div className="categroyDropDown mt-2">
                {member?.map((item, index) => (
                  <div key={index}>
                    {item?.userDetailId?.firstName ||
                    item?.userDetailId?.lastName ||
                    item?.userDetailId?.image?.url ? (
                      <div
                        className={`categoryGoal my-2 point ${
                          selectedMembers?.some(
                            (selected) => selected?.member?.id === item?._id
                          )
                            ? "selected"
                            : ""
                        }`}
                        key={index}
                        onClick={() => {
                          toggleMemberSelection({
                            member: {
                              id: item._id,
                              firstName: item?.userDetailId?.firstName || "",
                              lastName: item?.userDetailId?.lastName || "",
                              images: [
                                {
                                  url: item?.userDetailId?.image?.url || "",
                                },
                              ],
                            },
                          });
                        }}
                      >
                        {item?.userDetailId?.image?.url && (
                          <img
                            style={{ width: 30, height: 30, borderRadius: 30 }}
                            src={item?.userDetailId?.image?.url}
                            alt="User"
                          />
                        )}
                        {(item?.userDetailId?.firstName ||
                          item?.userDetailId?.lastName) && (
                          <span className="ms-1">
                            {item?.userDetailId?.firstName}{" "}
                            {item?.userDetailId?.lastName}
                          </span>
                        )}
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div iv className="field mt-3 negativePadding">
          <label htmlFor="name" className="font-bold py-2 pe-4 ">
            Description
          </label>
          <InputTextarea
            id="descriptionGoalTask"
            value={product?.description}
            onChange={(e) => {
              setProduct({ ...product, description: e.target.value });
              // setTaskDescription(e.target.value)
            }}
            placeholder="Input text"
            required
            rows={3}
            cols={20}
            className="p-0 ps-1 font-size-input-field"
          />
        </div>

        <div className="field position-relative mb-4 negativePadding">
          <div htmlFor="price" className="font-bold">
            Comments
          </div>
          <input
            id="comments"
            placeholder="Input text"
            className="w-100  p-1 mt-2 font-size-input-field"
            value={product?.comment}
            onValueChange={(e) => onInputNumberChange(e)}
            onChange={(e) => {
              setProduct({ ...product, comment: e.target.value });
            }}
          />
        </div>

        <div className="d-flex justify-content-center mb-4">
          <Buttons
            class={"tech-btn tech-btn-2 me-3"}
            data={product?._id ? "Update" : "Create"}
            onClick={() => {
              product?._id ? sendUpdatedTaskData() : sendCreateTaskData();
              setSelectedMembers([]);
            }}
          ></Buttons>
        </div>
      </Dialog>
    </div>
  );
};

export default Modal;
