import React from 'react';
import Button from "./Button";
import "./model.css"
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { clearAllCookies } from '../../lib/auth';
function CustomModal({ title, content, onClose }) {
    const navigate = useNavigate();
    const handleLogout = () => {
        clearAllCookies()
        secureLocalStorage.clear();
        secureLocalStorage.removeItem("id");
        secureLocalStorage.removeItem("image");
        navigate("/login");
    };
    return (
        <div className="custom-modal">
            <div className="modal-content">
                <h4>{title}!</h4>
                {/* <p>{content}</p> */}
                <div className=''>
                    <div className="py-3">
                        <Button style={{ width: '100%' }} data={"Login"} class={"profile-btn"} onClick={() => { handleLogout(); onClose() }}>Login</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomModal;
