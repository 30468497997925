export const addSubscriber = (data, notify) => {
  let id = [];
  if (notify) {
    id.push("65946bf1127a122741eb5bf0");
  } else {
    id.push("659e7efb7fa26331f22f7a87");
  }

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Authorization",
    "Basic ZmRfa2V5XzY4NTA2MjFjMTU2ZjQ4YjZiMmMwYTdhMDQzOGJiZDE4LnY2Zm9oU3pDS0dHbjF1TTlpWDV1Y2pVZ2piOUdiV1J1SXE3R0l1NmNla3BQbmJIY0pBMFkxS1NGQmFMVGVuZmoxTmJPRVN1WTI1dWNHVU4xalY0WDVjNFlEV3RBcmFMMjI2akNhcWMyTjBGVkF3MGxUdzNFYkNMNDFvc0JEemJNS2VhWnFLckdPSExTcVdybHdoQWxqTExsdW05c3Y2bjZ5UkNERnNNU0JJdUE1UzNFZ0t3cFlyZmdVSTZtOE1IOTo="
  );

  var raw = JSON.stringify(data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch("https://api.flodesk.com/v1/subscribers", requestOptions)
    .then((response) => response.json())
    .then((result) => {
      addToSegmants(id, data.email);
    })
    .catch((error) => console.log("error", error));
};

const addToSegmants = (id, email) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Authorization",
    "Basic ZmRfa2V5XzY4NTA2MjFjMTU2ZjQ4YjZiMmMwYTdhMDQzOGJiZDE4LnY2Zm9oU3pDS0dHbjF1TTlpWDV1Y2pVZ2piOUdiV1J1SXE3R0l1NmNla3BQbmJIY0pBMFkxS1NGQmFMVGVuZmoxTmJPRVN1WTI1dWNHVU4xalY0WDVjNFlEV3RBcmFMMjI2akNhcWMyTjBGVkF3MGxUdzNFYkNMNDFvc0JEemJNS2VhWnFLckdPSExTcVdybHdoQWxqTExsdW05c3Y2bjZ5UkNERnNNU0JJdUE1UzNFZ0t3cFlyZmdVSTZtOE1IOTo="
  );

  var raw = JSON.stringify({
    segment_ids: id,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    `https://api.flodesk.com/v1/subscribers/${email}/segments`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {})
    .catch((error) => console.log("error", error));
};
