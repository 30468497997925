import React from 'react';

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <div className={`modal fade  bg-blur-confirm ${isOpen ? 'show d-block' : ''}`} tabIndex="-1" role="dialog" style={{zIndex:999999}}>
      <div className="modal-dialog modal-dialog-centered" role="document" >
        <div className="modal-content" style={{zIndex:9999999}}>
          <div className="modal-header pb-2">
            <h5 className="modal-title">Confirm Deletion</h5>
         
          </div>
          <div className="modal-body py-2">
            <p>Are you sure you want to delete this message? This action cannot be undone.</p>
          </div>
          <div className="modal-footer pt-2">
          <button type="button" className="btn btn-danger" onClick={()=>onConfirm()}>
              Yes, Delete
            </button>
            <button type="button" className="btn btn-secondary" onClick={()=>onClose()}>
              No, Cancel
            </button>
          
          </div>
        </div>
      </div>
      {isOpen && <div className="modal-backdrop fade show"></div>}  
    </div>
  );
};

export default ConfirmationModal;
