import React, { useRef, useState } from "react";
import { MdClose } from "react-icons/md";
import ContactDetatils from "./OrgContactDetails";
import secureLocalStorage from "react-secure-storage";
import { getCookies } from "../../../lib/auth";
export const ContactOffCanvas = ({ data, fetchConversations, buddy, blockUser, sendMessage }) => {
    const [smallScreen, setSmallScreen] = useState("block");
    const [canvasScreen, setCanvasScreen] = useState(false);
    // const [buddy, setBuddy] = useState();
    const [conversations, setConversations] = useState([]);
    // const [data, setData] = useState({});

    const [show, setShow] = useState(false);

    const ref = useRef(null);
    const [activeIndex, setActiveIndex] = useState(null);

    const token = getCookies("token");



    return (
        <div className="chatCanvas">
            <div
                class="offcanvas offcanvas-start pt-5  pe-1"
                tabindex="-1"
                id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel"
            >
                <div class="offcanvas-body ">
                    <div
                        className="ps-5 pe-1 py-4"
                        style={{
                            backgroundColor: "rgba(252, 252, 252, 1)",
                            height: "100vh",
                            overflowY: "scroll",
                        }}
                    >
                        <div className="text-end">
                            <MdClose
                                data-bs-dismiss="offcanvas"
                                fontSize={"30px"}
                                style={{ cursor: "pointer" }}
                            />
                        </div>

                        <ContactDetatils
                            data={data}
                            buddy={buddy}
                            blockUser={blockUser}
                            sendMessage={sendMessage}
                            fetchConversations={fetchConversations}

                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
