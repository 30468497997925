import img1 from '../../../asserts/images/TeacherContact2.png'
export const reviews = [

    {
        id: 1,
        review: "It is a great organization. The staff was super nice, II recommend!",
        image: img1,
        name: "Mark Lariviere",
        rating: 5,
        dateTime: 'May 27 2023',
        Description: 'I am incredibly grateful to have had the opportunity to learn Quran online with Ali. His expertise, patience, and dedication to teaching the Quran have truly made a profound impact on my spiritual journey. From the very first lesson, Ali created a warm and nurturing environment that made me feel comfortable and motivated to learn.His deep knowledge of the Quran and its teachings is evident in every session.He skillfully guided me through the recitation, understanding of Arabic pronunciation, and the meaning of various verses.'
    },

    {
        id: 2,
        image: img1,
        review: "It is a great organization. The staff was super nice, II recommend!",
        rating: 5,
        name: "Mark Lariviere",
        dateTime: 'May 27 2023',
        Description: 'I am incredibly grateful to have had the opportunity to learn Quran online with Ali. His expertise, patience, and dedication to teaching the Quran have truly made a profound impact on my spiritual journey. From the very first lesson, Ali created a warm and nurturing environment that made me feel comfortable and motivated to learn.His deep knowledge of the Quran and its teachings is evident in every session.He skillfully guided me through the recitation, understanding of Arabic pronunciation, and the meaning of various verses.'

    },
    {
        id: 3,
        image: img1,
        review: "It is a great organization. The staff was super nice, II recommend!",
        rating: 5,
        name: "Mark Lariviere",
        dateTime: 'May 27 2023',
        Description: 'I am incredibly grateful to have had the opportunity to learn Quran online with Ali. His expertise, patience, and dedication to teaching the Quran have truly made a profound impact on my spiritual journey. From the very first lesson, Ali created a warm and nurturing environment that made me feel comfortable and motivated to learn.His deep knowledge of the Quran and its teachings is evident in every session.He skillfully guided me through the recitation, understanding of Arabic pronunciation, and the meaning of various verses.'


    },


]