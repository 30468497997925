import React, { useEffect, useState } from "react";
import InputField from "../reuseable/InputField";
import { Loader } from "../reuseable/Loader";
import Button from "../reuseable/Button";
import axios from "axios";
import CheckBox from "../../OrganizationDashboard/OrgSidebarContent/Notification/CheckBox";
import CheckOut from "../SliderAuth/CheckOut";
import { showMessage } from "../reuseable/Tostify";
import { useNavigate } from "react-router-dom"; 
import moment from "moment";
import API_ENDPOINTS from "../../Routes/API_Routes";
const Resubscribtion = () => {
  const [showVerification, setShowVerifcation] = useState(true); 
  const [loader, setLoader] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState(""); 
  const navigate = useNavigate(); 
  const [pricingData, setPricingData] = useState([]);
  const [email, setEmail] = useState(""); 
  const [planDetails, setPlanDetails] = useState(null);
  const [sendDetails, setSendDetails] = useState(false);
  const [stripeData, setStripeData] = useState(null);
  const [paypalData, setPaypalData] = useState(null);
  const [roleData, setRoleData] = useState({
    value: "option1",
    label: "A student",
    prompt: "student",
  });
  const [isGoogle, setIsGoogle] = useState(false);
  const [uid, setUid] = useState("");

  const [showPakages, setShowPakage] = useState(false);
  const [showCardInfo, setShowCardInfo] = useState(false);

  const FetchPricingPlan = () => {
    setLoader(true);

    axios
      .get(API_ENDPOINTS.GET_PAKAGES)
      .then((response) => {
        if (response.status === 200) {
          setPricingData(response.data.data);
        } else {
          console.error(response.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching pricing plan:", error);
      })
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    FetchPricingPlan();
  }, []);

  const handleOptionChange1 = (event) => {
  
    setSelectedOption1(event.target.value);
    setShowCardInfo(true);
    setShowPakage(false);
  };

  const handleClick = (option) => {
    setLoader(false);
  
    setPlanDetails(option);
    setLoader(false);
  };

  const handleCheckUser = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email === "") {
      showMessage("Email is required", "error");
      return;
    }
   

    if (!emailRegex.test(email)) {
      showMessage("Please enter a valid email address", "error");
      return;
    }
    setLoader(true);
    const raw = JSON.stringify({
      email: email, 
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
 

    fetch(API_ENDPOINTS.CHECK_RE_SUBSCRIBE , requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.status === 200) {
          showMessage(result?.message);
          setLoader(false); 
          setUid(result?.data?.userId);
          setShowVerifcation(false);
          setShowPakage(true);
        } else if (result?.status === 300) {
          setLoader(false);
          showMessage("Your subscribtion has not been expired yet", "error");
          navigate(`/login`);
        } else {
          showMessage(result?.message, "error");
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (stripeData?.status === "succeeded") {
    
      RE_Subscribe();
    }
  }, [roleData, sendDetails, stripeData, paypalData]);

  const [sixthCard, setSixthCard] = useState(false);

  const [seventhCard, setSeventhCard] = useState(false);

  const handleCheckoutButtonClick = () => {
    console.log("clicked");
  };

 

  const sendPlans = [
    { SubscriptionPrice: `€ ${stripeData?.amount / 100}` },
    { SubscriptionPlan: planDetails?.name },
    { PaymentMethod: stripeData?.payment_method_details?.card?.brand },
    { OrderID: stripeData?.id },
    { Card: stripeData?.payment_method_details?.card?.brand },
    { exp_month: stripeData?.payment_method_details?.card?.exp_month },
    { exp_year: stripeData?.payment_method_details?.card?.exp_year },
    { TransactionDate: moment.unix(stripeData?.created).format("YYYY-MM-DD") },
    { FuturePayment: false },
  ];

  const RE_Subscribe = () => {
    setLoader(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const raw = JSON.stringify({
      email: email,
      planId: planDetails?._id,
      details: JSON.stringify(sendPlans),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(API_ENDPOINTS.RE_SUBSCRIBE ,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 200) {
          showMessage(result?.message);
          setLoader(false);
          navigate(`/login`);
        } else {
          showMessage(result?.message);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoader(false);
      });
  };
 
  return (
    <section>
      {loader && (
        <div className="loaderScreen">
          <Loader />
        </div>
      )}
      {showVerification ? (
        <div className="px-zero light-images">
          <div className="signInBackgroundImage">
            <div className="d-lflex">
              <div className="login-row">
                <div
                  style={{ padding: "3rem" }}
                  className="resubscribtion login-rowMain"
                >
                  <div className="login-body">
                    <h6 className="text-center re-subscribe-heading">
                      {"Update Subscription!"}
                    </h6>
                    <div className="LoginTime">
                      <hr style={{ margin: "0.5rem 0" }} />
                      <div>
                        <pre className="SignInEMail px-2">
                          Enter details to renew your subscription
                        </pre>
                      </div>
                      <hr style={{ margin: "0.5rem 0" }} />
                    </div>

                    <div className="login-form">
                      <div className="mb form-main w-100 mt-3">
                        <label htmlFor="email">{"E-mail"}</label>
                        <InputField
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      

                      <div>
                        <Button
                          type={"submit"}
                          class={"bottom-btn profile-btn w-100"}
                          data={"Verify"}
                          onClick={handleCheckUser}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {showPakages ? (
        <div className="px-zero light-images">
          <div className="signInBackgroundImage">
            <div className="d-lflex">
              <div className="login-row">
                <div
                  style={{ padding: "3rem" }}
                  className="resubscribtion login-rowMain width-resub "
                >
                  <div className="login-body">
                    <h6 className="text-center re-subscribe-heading">
                      {"Update Subscription!"}
                    </h6>
                    <div className="LoginTime">
                      <hr style={{ margin: "0.5rem 0" }} />
                      <div>
                        <pre className="SignInEMail px-2">Select a plan</pre>
                      </div>
                      <hr style={{ margin: "0.5rem 0" }} />
                    </div>

                    <div className="login-form">
                      <div>
                        {pricingData.map((option) => (
                          <label key={option._id} className="signUpPricePage">
                            <div className="d-flex align-items-start gap-2">
                              <span>
                                {"Student "} {option.name} {" Plan"}
                              </span>
                            </div>
                            <h4 className="mt-3 priceLabelSignUp text-sm-center text-md-start">
                              € {option.price} {`/${option.name}`}
                            </h4>

                            <div onClick={() => handleClick(option)}>
                              <CheckBox
                                value={option._id}
                                checked={selectedOption1 === option._id}
                                onChange={handleOptionChange1}
                                className="rounded-circle"
                              />
                            </div>
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {showCardInfo ? (
        <div className="px-zero light-images">
          <div className="signInBackgroundImage" style={{ minHeight: "100vh" }}>
            <div className="d-lflex">
              <div className=" ">
                <div className="  pt-5  ">
                  <div className="login-body     rounded-3">
                    <div className=" ">
                      <div>
                        <div>
                          {planDetails && (
                            <CheckOut
                              bg={true}
                              setSendDetails={setSendDetails} //get
                              setStripeData={setStripeData} //get
                              planDetails={planDetails} // okay
                              roleData={roleData} //okay
                              setPaypalData={setPaypalData} //get
                              email={email} //okay
                              isGoogle={isGoogle} //get
                              uid={uid} //okay
                              onButtonClick={handleCheckoutButtonClick}
                              onButtonClick2={handleCheckoutButtonClick}
                              setSixthCard={setSixthCard}
                              setSeventhCard={setSeventhCard}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </section>
  );
};

export default Resubscribtion;
