



export const Availdata = [
    {
        time: "0.00"
    },
    {
        time: "0.00"
    },
    {
        time: false
    },
    {
        time: "0.00"
    },
    {
        time: "0.00"
    },
    {
        time: false
    },
    {
        time: "0.00"
    },
    {
        time: "0.30"
    },
    {
        time: "0.30"
    },
    {
        time: false
    },
    {
        time: "0.30"
    },
    {
        time: "0.30"
    },
    {
        time: false
    },
    {
        time: "0.30"
    },

    {
        time: "1.00"
    },
    {
        time: "1.00"
    },
    {
        time: false
    },
    {
        time: "1.00"
    },
    {
        time: "1.00"
    },
    {
        time: false
    },
    {
        time: "1.00"
    },

    {
        time: "1.30"
    },
    {
        time: "1.30"
    },
    {
        time: false
    },
    {
        time: "1.30"
    },
    {
        time: "1.30"
    },
    {
        time: false
    },
    {
        time: "1.30"
    },

    {
        time: "2.00"
    },
    {
        time: "2.00"
    },
    {
        time: false
    },
    {
        time: "2.00"
    },
    {
        time: "2.00"
    },
    {
        time: false
    },
    {
        time: "2.00"
    },

    {
        time: "3.30"
    },
    {
        time: "3.30"
    },
    {
        time: false
    },
    {
        time: "3.30"
    },
    {
        time: "3.30"
    },
    {
        time: false
    },
    {
        time: "3.30"
    },

    {
        time: "4.00"
    },
    {
        time: "4.00"
    },
    {
        time: false
    },
    {
        time: "4.00"
    },
    {
        time: "4.00"
    },
    {
        time: false
    },
    {
        time: "4.00"
    },

    {
        time: "5.00"
    },
    {
        time: "5.00"
    },
    {
        time: false
    },
    {
        time: "5.00"
    },
    {
        time: "5.00"
    },
    {
        time: false
    },
    {
        time: "5.00"
    },



    {
        time: "5.30"
    },
    {
        time: "5.30"
    },
    {
        time: false
    },
    {
        time: "5.30"
    },
    {
        time: "5.30"
    },
    {
        time: false
    },
    {
        time: "5.30"
    },

    {
        time: "6.00"
    },
    {
        time: "6.00"
    },
    {
        time: false
    },
    {
        time: "6.00"
    },
    {
        time: "6.00"
    },
    {
        time: false
    },
    {
        time: "6.00"
    },


]