import React, { useEffect, useState } from "react";

//-----------images----------------------------//
import left from "../asserts/images/auth left.png";
import right from "../asserts/images/auth right.png";
import { t } from "i18next";
import Button from "../components/reuseable/Button";
import Header from "../layout/Header";
import { Loader } from '../components/reuseable/Loader'
import { showMessage } from "../components/reuseable/Tostify";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import API_Routes from "../Routes/API_Routes";
import DashboardNavbarContact from "../Dashboard/DashboardCmp/DashboardNavbarContact";
import ContactHeader from "../layout/ContactHeader";
import { getCookies } from "../lib/auth";


const DonationThankYou = () => {
    const [loader, setLoader] = useState(false);
    const token = getCookies("token") 
    const navigate = useNavigate();
    useEffect(() => {
      if (!token) {
        navigate('/login')
      }
    }, [ ])
    const location = useLocation()

    const { contactDetails } = location?.state || {};

    const sendCopyMsgToMail = () => {
        setLoader(true)
        var myHeaders = new Headers();
        // myHeaders.append("Accept", "application/json");
        // myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);

        var formdata = new FormData();
        formdata.append("id", contactDetails?._id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(API_Routes.SENDACOPY, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 200 || result.status === 201) {
                    setLoader(false)
                    showMessage(result?.message);
                } else {
                    showMessage(result?.message, 'error');
                    setLoader(false)

                }
            })
            .catch(error => {
                setLoader(false)
                console.log('error', error);
            });
    }

    return (
        <>
            <section>
                {loader && <div className="loaderScreen">
                    <Loader />
                </div>}
                <div className="container">
                    {token ? <DashboardNavbarContact /> : <div className='container gx-5'><ContactHeader /> </div>}
                </div>
                <div className="container-fluid px-zero light-images">
                    <div className="img-left-side thank-left">
                        <img className="img-fluid" src={left} alt="" />
                    </div>
                    <div className="img-right-side">
                        <img className="img-fluid" src={right} alt="" />
                    </div>
                    <div className="container">
                        <div>
                            <div className="row align-items-center justify-content-center thanks-row contactThanksMain">

                                <div className="contactCenterText">
                                    <h6 style={{ fontSize: 42 }} className="text-start text-dark">Thank you so much for your support!</h6>

                                    <div className="mt-5">
                                        <p className="text-start ">We have sent an email with donation detail and invoice to your email address:<br />
                                            <span className="organizationEmailtxt">12345@gmail.com</span> </p>
                                        <p className="text-start ">You can always download donation detail and invoice:</p>

                                        <div className="text-start ">
                                            <Button 
                                             data={'Download Donation sheet (PDF)'} class={'thankYouDonationBtn'} />
                                        </div>


                                        <div className="thankYouTxt2">
                                            <p className="text-start mb-0">
                                                If you have any questions or require further information, please don't hesitate to reach out to us. We are here to assist you.
                                            </p>
                                        </div>

                                        <ul className="ps-2 orgOptiontxt" style={{ listStyle: "inside" }}>
                                            <li>
                                                Islamic Cultural Association
                                            </li>
                                            <li>

                                                Email: <span className="emailSpantxt">info@islamicculture.org</span>
                                            </li>
                                            <li>
                                                Phone: <span>+1 (555) 123-4567</span>
                                            </li>
                                        </ul>

                                        <div className="thankYouTxt3" >
                                            <p className="text-start">Once again, thank you for your generous contribution. Your support is invaluable in helping us preserve and promote Islamic culture. We are honored to have you as part of the Islamic Cultural Association's community and mission.</p>
                                        </div>

                                        <div className="thankYouTxt4" >
                                            <p className="text-start mb-0">Warm regards,</p>
                                        </div>

                                        <div className="ilmCircleTeamTxt2">
                                            <p className="text-start">
                                                The <span className="orange-text">I</span>lm <span className="orange-text">C</span>ircle Team
                                            </p>
                                        </div>
                                    </div>
                                </div>



                            </div>


                        </div>


                    </div>
                </div>
            </section>
        </>
    );
};

export default DonationThankYou;
