import Cookies from 'js-cookie';
import CryptoJs from "crypto-js";

const _key = "7d7cd92a9c3055f30f8943b5092abb8e";

export function storeCookies(value , token )    {
  const encryptedToken = CryptoJs.AES.encrypt(token, _key).toString();
  Cookies.set(value, encryptedToken, {
    expires: 7
  });
}

export function getCookies(value )  {
  const encryptedToken = Cookies.get(value);
  if (encryptedToken) {
    const decryptedToken = CryptoJs.AES.decrypt(encryptedToken, _key);
    return decryptedToken.toString(CryptoJs.enc.Utf8) ?? '';
  }
  return '';
}

const cookiesToClear = [
  'token' 
];

export function clearAllCookies()  {
  cookiesToClear.forEach(cookieName => {
    if (Cookies.get(cookieName)) {
      Cookies.remove(cookieName, {
        path: '/',
        maxAge: 0,
      });
    }
  });
}

// Get and decrypt cookie
const getAndDecryptCookie = (cookieName )  => {
  const encryptedValue = Cookies.get(cookieName);
  if (encryptedValue) {
    const decryptedToken = CryptoJs.AES.decrypt(encryptedValue, _key);
    return decryptedToken.toString(CryptoJs.enc.Utf8) ?? '';
  }
  return '';
};

export default getAndDecryptCookie;
