// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.org-form h6 {
  color: var rgba(37, 39, 39, 0.78);
  text-align: center;

  /* Header 2 */
  font-family: "Ysabeau";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 48px */
  letter-spacing: -0.5px;
}

.MuiList-root .MuiListItem-root:nth-child(1) * {
  border-radius: 8px !important;
  color: orange !important;
}

.MuiListItemButton-gutters:hover {
  background-color: #f6e5cf !important;
  border-radius: 8px !important;
}`, "",{"version":3,"sources":["webpack://./src/OrganizationDashboard/OrgSidebarContent/Organization-Details/Organizationdetails.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,kBAAkB;;EAElB,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,6BAA6B;EAC7B,wBAAwB;AAC1B;;AAEA;EACE,oCAAoC;EACpC,6BAA6B;AAC/B","sourcesContent":[".org-form h6 {\n  color: var rgba(37, 39, 39, 0.78);\n  text-align: center;\n\n  /* Header 2 */\n  font-family: \"Ysabeau\";\n  font-size: 48px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 100%;\n  /* 48px */\n  letter-spacing: -0.5px;\n}\n\n.MuiList-root .MuiListItem-root:nth-child(1) * {\n  border-radius: 8px !important;\n  color: orange !important;\n}\n\n.MuiListItemButton-gutters:hover {\n  background-color: #f6e5cf !important;\n  border-radius: 8px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
