export const favoriteTeacher = [

    {
        id: '1',
        image: require('../../asserts/images/favTeacher.png'),
        name: "Fatima Al-Rashid",
        Location: "Bruxelles, Belgium",
        Riwaya: 'Hafs, Warsh',
        Ijazah: "Yes",
        Language: "French, English "
    },
    {
        id: '2',
        image: require('../../asserts/images/favTeacher.png'),
        name: "Fatima Al-Rashid",
        Location: "Bruxelles, Belgium",
        Riwaya: 'Hafs, Warsh',
        Ijazah: "Yes",
        Language: "French, English "

    },
    {
        id: '3',
        image: require('../../asserts/images/favTeacher.png'),
        name: "Fatima Al-Rashid",
        Location: "Bruxelles, Belgium",
        Riwaya: 'Hafs, Warsh',
        Ijazah: "Yes",
        Language: "French, English "
    },
    {
        id: '4',
        image: require('../../asserts/images/favTeacher.png'),
        name: "Fatima Al-Rashid",
        Location: "Bruxelles, Belgium",
        Riwaya: 'Hafs, Warsh',
        Ijazah: "Yes",
        Language: "French, English "
    },
];