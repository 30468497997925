import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import { CurrentPlan } from "./CurrentPlan";
import secureLocalStorage from "react-secure-storage";
import API_Routes from "../../../Routes/API_Routes";
import { showMessage } from "../../../components/reuseable/Tostify";
import { Loader } from "../../../components/reuseable/Loader";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { getCookies } from "../../../lib/auth";
export const Invoice = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    GetInvoiceData();
  }, []);
  const [details, setDetails] = useState();
  const GetInvoiceData = () => {
    const token = getCookies("token")

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(API_Routes.GETSUBSCRIPTIONINVOICE, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if ((result.status = 200 || result.status == 201)) { 
          setLoader(false); 
          result?.data?.forEach((item) => {
            item.details = JSON.parse(item?.details);
          });

          const extractedData = result?.data?.map((item) => {
            const dateOfPlan = moment(item?.date)
              .utc()
              .format("HH:mm MMMM DD YYYY");
            const subscriptionPrice =
              item?.details?.find((detail) => detail?.SubscriptionPrice)
                ?.SubscriptionPrice || null;
            const subscriptionPlan =
              item?.details?.find((detail) => detail?.SubscriptionPlan)
                ?.SubscriptionPlan || null;
            const transactionDetail = item?.details?.find(
              (detail) => detail?.TransactionDate
            );
            const transactionDate = transactionDetail
              ? moment(transactionDetail?.TransactionDate).format(
                  "MMMM DD YYYY"
                )
              : null;
            const transactionYear = transactionDate
              ? new Date(transactionDate).getFullYear()
              : null;

            return {
              _id: item?._id,
              subscriptionPrice,
              subscriptionPlan,
              transactionDate,
              transactionYear,
              dateOfPlan,
            };
          });
          setDetails(extractedData);
        } else {
          showMessage(result?.message, "error");
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoader(false);
      });
  };

  // Group data by year
  const groupedData = data?.reduce((acc, item) => {
    const year = moment(item.date).year();
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(item);
    return acc;
  }, {});
  return (
    <div>
      {loader && (
        <div className="loaderScreen">
          <Loader />
        </div>
      )}{" "}
      <div>
        {details?.map((item) => (
          <div key={item?.transactionYear}>
            <h4 className="mt-5">{item?.transactionYear}</h4>
            <CurrentPlan
              date={item?.transactionDate}
              text={item?.subscriptionPrice}
              class="SubPriceTextColor"
            />
          </div>
        ))}

         
      </div>
    </div>
  );
};
