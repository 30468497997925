import React from "react";
import { BiRightArrowAlt } from "react-icons/bi";
import { BsArrowRepeat } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { EventsClass } from "../EventClass/EventsClass";
import { MiniCalender } from "./MiniCalender";


import { useDispatch, useSelector } from "react-redux";
import { actionCreaters } from "../../../Store/Index";
import { bindActionCreators } from "redux";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { setAyat, setGoalTaskData } from "../../../Store/Actions";
import API_Routes from "../../../Routes/API_Routes";


import { useState } from "react";
import { Loader } from "../../../components/reuseable/Loader";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { showMessage } from '../../../components/reuseable/Tostify'
import { useNavigate } from "react-router-dom";
import { EventsSeprate } from "../EventClass/EventsSeprate";
import { getCookies } from "../../../lib/auth";

export const HeaderSection = ({ divHeight }) => {
  const dispatch = useDispatch();
  const { studDashboardIndex } = bindActionCreators(actionCreaters, dispatch);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loader, setLoader] = useState(false);

  const [memorizationTip, setMemorizationTip] = useState([]);

  const goalTask = useSelector((state) => state.goalTask);


  const token = getCookies("token");
  const navigate = useNavigate();


  const slideStatus = secureLocalStorage.getItem('slider')
  useEffect(() => {

    GetTaskList();

  }, []);


  useEffect(() => {
    const slideStatus = secureLocalStorage.getItem('slider');
    setIsPlaying(slideStatus === "Play");
  }, []);
  const GetTaskList = () => {
    setLoader(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(API_Routes.GETALLGOALTASK, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 200 || result.status == 201) {
          dispatch(setGoalTaskData(result?.data));
          setLoader(false)

        } else {
          setLoader(false)
          showMessage(result?.message)
        }
      })
      .catch((error) => {
        setLoader(false)
        console.log("error", error);
      });
  };








  // const dueDate = (date) => {
  //   const [day, month, year] = date.split("/").map(Number);
  //   console.log('date', date)

  //   const inputDate = new Date(year, month - 1, day);

  //   const currentDate = new Date();

  //   currentDate.setHours(0, 0, 0, 0);

  //   const timeDifference = currentDate - inputDate;

  //   const daysAgo = Math.floor(timeDifference / (24 * 60 * 60 * 1000));

  //   if (daysAgo === 0) {
  //     return "Today";
  //   } else if (daysAgo === 1) {
  //     return "Yesterday";
  //   } else {
  //     return `${daysAgo} days ago`;
  //   }
  // };


  const dueDate = (dateString) => {
    const inputDate = new Date(dateString);

    const currentDate = new Date();

    // Set both dates to the same time (midnight) for accurate comparison
    inputDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    const timeDifference = currentDate - inputDate;

    const daysAgo = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
    if (daysAgo === 0) {
      return "Today";
    } else if (daysAgo === 1) {
      return "Yesterday";
    } else if (daysAgo < 0) {
      return `${Math.abs(daysAgo)} days left`;
    }
    else {
      return `${daysAgo} days ago`;
    }
  };

  const date = "2023-10-04";
  const formattedDate = dueDate(date);




  const sliderSettings = {
    dots: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };




  return (
    <div>
      {loader && (
        <div className="loaderScreen">
          <Loader />
        </div>
      )}
      <div className="row gy-5">
        <div className="col-lg-6 mt-5">
          <MiniCalender divHeight={divHeight} />
        </div>
        <div className="col-lg-6 mt-5">
          <div style={{  height: divHeight }} className="mainGoalsAndTask margin-dashboard-calender ms-sm-0 ms-1">
            <div
              className="d-flex justify-content-between sticky-top "
              style={{ background: "#fcfcfc", paddingTop: 24 }}
            >
              <h2 className="mainGoalsAndTaskHeading">My goals and tasks</h2>
              <BiRightArrowAlt
                fontSize={"25px"}
                className="point"
                onClick={() => navigate(`/student-dashboard/${3}`)}
              />
            </div>

            {goalTask?.length ? goalTask?.map((item, index) => (
              <div key={index} className="subMenues d-flex justify-content-between align-items-center">
                <h3 className="subMenuesTitle ">{item?.name}</h3>
                <div>
                  <p className="subMenuesTitleSidebar mb-0 text-center">
                    {dueDate(item?.dueDate)}
                  </p>
                  {item?.status == "In progress" ? (
                    <pre className=" progressDiv mb-0">
                      <span className=" dot"></span>
                      <span className="text text-capitalize">
                        {item?.status}
                      </span>
                    </pre>
                  ) : item.status == "Done" ? (
                    <pre
                      className=" progressDiv mb-0"
                      style={{ backgroundColor: "#C5E4D7" }}
                    >
                      <span
                        className="dot"
                        style={{ background: "#2FA070" }}
                      ></span>
                      <span className="text text-capitalize">
                        {item?.status}
                      </span>
                    </pre>
                  ) : (
                    <pre
                      className=" progressDiv mb-0"
                      style={{ backgroundColor: "#E0E0DB" }}
                    >
                      <span
                        className=" dot"
                        style={{ background: "#404040" }}
                      ></span>
                      <span className="text text-capitalize">
                        {item?.status}
                      </span>
                    </pre>
                  )}
                </div>
              </div>
            )) :
              <div
                className="NoEventsTxt text-center fs-6 mt-3"
                style={{ color: "#f47b00" }}
              >
                No goals & task found
              </div>
            }
          </div>
        </div>
      </div>


    </div >
  );
};
