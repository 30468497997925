import React, { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { messageArray } from "./Dummy";
import { AiOutlineRight } from "react-icons/ai";
import rightArrow from '../../../asserts/images/rightarro.png'
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import API_Routes, { url } from "../../../Routes/API_Routes";
import { getCookies } from "../../../lib/auth";

export const MessageSection = ({ orgDashboardIndex, smallerDivHeight }) => {
  const navigate = useNavigate()
  const [message, setMessage] = useState([]);

  const accessToken = getCookies("token");

  useEffect(() => {
    fetchConversations()
  }, [])
  const fetchConversations = async () => {
    try {
      var myHeaders = new Headers();

      myHeaders.append("Authorization", "Bearer " + accessToken);
      const res = await fetch(`${url}api/organization/message/users`, {
        method: "GET",
        headers: myHeaders,
      });
      const resData = await res.json();

      if (resData.status == 200) {
        setMessage(resData?.data)


      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatLastMessageTime = (dateString) => {
    const currentDate = new Date();
    const inputDate = new Date(dateString);

    const timeDiff = currentDate - inputDate;
    const daysDiff = timeDiff / (1000 * 3600 * 24);

    if (daysDiff < 1) {
      return inputDate.toLocaleTimeString();
    } else if (daysDiff < 2) {
      return "Yesterday";
    } else if (daysDiff < 7) {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return days[inputDate.getDay()];
    } else {
      const options = { year: "numeric", month: "numeric", day: "numeric" };
      return inputDate.toLocaleDateString(undefined, options);
    }
  };
  return (
    <div className="pAll-24px sectionBg">
      <div className="header d-flex justify-content-between">
        <h3>Messages</h3>
        <img src={rightArrow} width={24} height={24} alt="" className="pointer"
          onClick={() => {
            navigate(`/organization-dashboard/${8}`)


          }}
        />
      </div>


      <div style={{ maxHeight: '300px', height: smallerDivHeight }} className="allItemMain">
        {message.length ? message.map((item) => (
          <div className="itemMessage d-flex align-items-center justify-content-between">
            <div className="d-flex ">

              <div class="position-relative">
                <img style={{ borderRadius: 30 }} src={item?.contactId?.userDetailId?.image?.url} alt="" />
                {/* <span class="smallCardDot"></span> */}
              </div>

              <div className="ps-3">
                <h4 className="name mb-0">{item?.userId?.userDetailId?.orgName}</h4>
                <p className="mb-0 content">{item?.lastMessage}</p>
              </div>
            </div>
            <div className="d-flex flex-wrap align-items-center gap-2 text-center">
              <h4 className="mb-0 content">
                {item.time}
                {formatLastMessageTime(
                  item?.date
                )}
                <br />
                {/* <span className="innerText mb-0">3</span> */}
              </h4>
              <AiOutlineRight />
            </div>

          </div>
        ))
          :
          <div className='MediaEdit mt-3 d-flex align-items-center justify-content-center '>
            <h4>No Data Found</h4>
          </div>
        }
      </div>
    </div>
  );
};


