export const favoriteOrganization = [

    {
        id: '1',
        image: require('../../asserts/images/favOrganization.png'),
        name: "Islamic Culture Center",
        person: "Organized by Ali and 3 others",
        members: '300 members',
        location: "Rue de la Montagne 14 1000 Brussels Belgium",
        link: "www.belgiumislamic.com"
    },
    {
        id: '2',
        image: require('../../asserts/images/favOrganization.png'),
        name: "Islamic Culture Center",
        person: "Organized by Ali and 3 others",
        members: '300 members',
        location: "Rue de la Montagne 14 1000 Brussels Belgium",
        link: "www.belgiumislamic.com"

    },
    {
        id: '3',
        image: require('../../asserts/images/favOrganization.png'),
        name: "Islamic Culture Center",
        person: "Organized by Ali and 3 others",
        members: '300 members',
        location: "Rue de la Montagne 14 1000 Brussels Belgium",
        link: "www.belgiumislamic.com"
    },
    {
        id: '4',
        image: require('../../asserts/images/favOrganization.png'),
        name: "Islamic Culture Center",
        person: "Organized by Ali and 3 others",
        members: '300 members',
        location: "Rue de la Montagne 14 1000 Brussels Belgium",
        link: "www.belgiumislamic.com"
    },
];