// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nointerimage {
  height: 100%;
  width: 100%;
}

@media (max-width: 576px) {
  .main-btn-2 {
      width: 132px;
      height: 38px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/views/nointernet/nointernet.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE;MACI,YAAY;MACZ,YAAY;IACd;AACJ","sourcesContent":[".nointerimage {\n  height: 100%;\n  width: 100%;\n}\n\n@media (max-width: 576px) {\n  .main-btn-2 {\n      width: 132px;\n      height: 38px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
