import img1 from '../../../asserts/images/buddyContact1.png'
import img2 from '../../../asserts/images/buddyContact2.png'
import img3 from '../../../asserts/images/buddyContact3.png'

import img4 from '../../../asserts/images/TeacherContact1.png'
import img5 from '../../../asserts/images/TeacherContact2.png'
import img6 from '../../../asserts/images/TeacherContact3.png'
import img7 from '../../../asserts/images/TeacherContact4.png'


export const Attendee = [

    {
        id: 1,
        name: "Sandra",
        image: img1,


    },

    {
        id: 2,
        image: img2,
        name: "Ahmed",
    },
    {
        id: 3,
        image: img3,
        name: "Fatima",

    },
    {
        id: 4,
        name: "Ahmed",
        image: img3

    },

    {
        id: 5,
        image: img4,
        name: "Mark",
    },
    {
        id: 6,
        image: img6,
        name: "Ahmed ",
    },
    {
        id: 7,
        image: img7,
        name: "Muhammad",
    },
    {
        id: 8,
        name: "Sandra",
        image: img1,

    },

    {
        id: 9,
        image: img2,
        name: "Ahmed",
    },
    {
        id: 10,
        image: img3,
        name: "Fatima",

    },
    {
        id: 11,
        name: "Ahmed",
        image: img3

    },

    {
        id: 12,
        image: img4,
        name: "Mark",
    },

]