import React, { useEffect } from "react";
import { EventAndClass } from "./EventClass/EventAndClass";
import { EventSection } from "./EventClass/EventSection";
import { getCookies } from "../../lib/auth";
import { useNavigate } from "react-router-dom";

const EventsClass = () => {
    
  const token = getCookies("token");
    const navigate = useNavigate();
    useEffect(() => {
      if (!token) {
        navigate('/login')
      }
     
    }, []);
    return (
        <div className="AllMainSection">
            <EventAndClass />
            <EventSection />
        </div>
    );
};

export default EventsClass;
