// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal10 {
    padding: 0 !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: left 3s ease;
    z-index: 99999;
}

.modal-content10 {
    overflow: auto;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 25px 20px;

    height: -webkit-fill-available;
    width: 100%;
}

@keyframes slideFromLeft {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}



@keyframes slideToLeftAndFadeOut {
    0% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(-100%);
        opacity: 0;
    }
}

.custom-modal10 {
    animation: slideFromLeft 0.5s ease;
}

.custom-modal10.closing {
    animation: slideToLeftAndFadeOut 0.5s ease;
    animation-fill-mode: forwards;
}`, "",{"version":3,"sources":["webpack://./src/StudentDashboard/SidebarContent/Contact/offcanvascontact.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,wBAAwB;IACxB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,mBAAmB;IACnB,uCAAuC;IACvC,kBAAkB;;IAElB,8BAA8B;IAC9B,WAAW;AACf;;AAEA;IACI;QACI,4BAA4B;IAChC;;IAEA;QACI,wBAAwB;IAC5B;AACJ;;;;AAIA;IACI;QACI,wBAAwB;QACxB,UAAU;IACd;;IAEA;QACI,4BAA4B;QAC5B,UAAU;IACd;AACJ;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,0CAA0C;IAC1C,6BAA6B;AACjC","sourcesContent":[".custom-modal10 {\n    padding: 0 !important;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    transition: left 3s ease;\n    z-index: 99999;\n}\n\n.modal-content10 {\n    overflow: auto;\n    background: #fff;\n    border-radius: 20px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n    padding: 25px 20px;\n\n    height: -webkit-fill-available;\n    width: 100%;\n}\n\n@keyframes slideFromLeft {\n    from {\n        transform: translateX(-100%);\n    }\n\n    to {\n        transform: translateX(0);\n    }\n}\n\n\n\n@keyframes slideToLeftAndFadeOut {\n    0% {\n        transform: translateX(0);\n        opacity: 1;\n    }\n\n    100% {\n        transform: translateX(-100%);\n        opacity: 0;\n    }\n}\n\n.custom-modal10 {\n    animation: slideFromLeft 0.5s ease;\n}\n\n.custom-modal10.closing {\n    animation: slideToLeftAndFadeOut 0.5s ease;\n    animation-fill-mode: forwards;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
