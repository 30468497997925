import React, { useEffect } from 'react'
import './auth.css'

//---------images---------------------//
import left from '../asserts/images/auth left.png'
import right from '../asserts/images/auth right.png'

//------------components-----------------//
import Header from '../layout/Header'
import SliderAuth from '../components/SliderAuth/SliderAuth'
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { getCookies } from '../lib/auth'
const Signup = () => {
    const role = secureLocalStorage.getItem("role");
    const token = getCookies("token")
    const navigate = useNavigate();
    useEffect(() => {
        if (token) {
            navigate(`${role === 'student' ? '/student-dashboard/0' : '/organization-dashboard/0'}`);
        }
    }, [])
    return (
        <>
            <section >
                <div className='container gx-5'>
                    <Header />
                </div>

                <div className="container-fluid px-zero light-images">

                    <div className='img-left-side'><img className='img-fluid' src={left} alt="" /></div>
                    <div className='img-right-side'><img className='img-fluid' src={right} alt="" /></div>
                    <div className="container">
                        <div className="row signup-main">
                            <div className="col">
                                <SliderAuth />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Signup
