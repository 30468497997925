// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
}

.modal-content {
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 25px 20px;

    width: 25rem;
    /* min-height: 12rem; */
    text-align: center;
}

@media (min-width: 1024px) {
    .modal-content {
        width: 30rem;
        padding: 40px;
        /* min-height: 15rem; */
    }
}

@media screen and (max-width: 450px) {
    .modal-content {
        /* padding: 15px; */

        width: 20rem;
        /* min-height: 10rem; */
    }

    .profile-btn {
        margin-right: 9 !important;
        /* width: 125px !important; */
    }

}`, "",{"version":3,"sources":["webpack://./src/components/reuseable/model.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,uCAAuC;IACvC,kBAAkB;;IAElB,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI;QACI,YAAY;QACZ,aAAa;QACb,uBAAuB;IAC3B;AACJ;;AAEA;IACI;QACI,mBAAmB;;QAEnB,YAAY;QACZ,uBAAuB;IAC3B;;IAEA;QACI,0BAA0B;QAC1B,6BAA6B;IACjC;;AAEJ","sourcesContent":[".custom-modal {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.6);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 99999;\n}\n\n.modal-content {\n    background: #fff;\n    border-radius: 20px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);\n    padding: 25px 20px;\n\n    width: 25rem;\n    /* min-height: 12rem; */\n    text-align: center;\n}\n\n@media (min-width: 1024px) {\n    .modal-content {\n        width: 30rem;\n        padding: 40px;\n        /* min-height: 15rem; */\n    }\n}\n\n@media screen and (max-width: 450px) {\n    .modal-content {\n        /* padding: 15px; */\n\n        width: 20rem;\n        /* min-height: 10rem; */\n    }\n\n    .profile-btn {\n        margin-right: 9 !important;\n        /* width: 125px !important; */\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
